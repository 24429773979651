import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

import { Form } from '../components'

import {
  EventConfiguration,
  FormData,
  ReportScheduleConfiguration,
} from '../components/Form/types'

import { useToast } from 'shared/hooks'

import {
  converter,
  groupWorkDays,
  handleGetPeriodicity,
} from 'domains/customer/screens/Contact/utilities'

import { handleRenderState } from 'utilities/handleRenderState'

import { Breadcrumbs, ContainerScreen, Loader } from 'components'
import { useEditContact, useGetContact } from 'services/contact'

import styles from './styles.module.scss'
import { useCustomerContext } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'
import { buildPath, paths } from 'routes'

const Update = () => {
  const { addToast } = useToast()
  const { accountId = '' } = useParams()
  const navigate = useNavigate()

  const { customer } = useCustomerContext()

  const customerId = customer?.id || ''

  const { contactId } = useParams()

  const { mutate, isPending } = useEditContact(contactId)
  const { data: contact, isLoading, isError } = useGetContact(contactId)

  const onSubmit = useCallback(
    (data: FormData) => {
      const payload = converter(data)

      mutate(payload, {
        onSuccess: () => {
          addToast({ message: 'Contato editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          const message = 'Erro ao editar contato. Tente novamente.'
          addToast({ message, type: 'alert' })
        },
      })
    },
    [addToast, navigate],
  )

  useEffect(() => {
    if (isError) {
      addToast({ message: 'Erro ao recuperar dados do contato', type: 'alert' })
      navigate(-1)
    }
  }, [isError])

  return (
    <>
      {
        {
          view: contact && (
            <>
              <Loader isVisible={isPending} />

              <div className={styles.container}>
                <ContainerScreen
                  clickable
                  renderBreadcrumbs={
                    <Breadcrumbs
                      items={[
                        {
                          title: 'Clientes',
                          href: buildPath({
                            path: paths.account.details,
                            params: { accountId },
                          }),
                        },
                        {
                          title: customer?.name || '',
                          href: buildPath({
                            path: paths.account.details + '?tab=contact&page=1',
                            params: { accountId },
                          }),
                        },
                        {
                          title: 'Contatos',
                          href: buildPath({
                            path: paths.account.details + '?tab=contact&page=1',
                            params: { accountId },
                          }),
                        },
                        { title: 'EDITAR CONTATO', href: '#' },
                      ]}
                    />
                  }
                >
                  <Form
                    onSubmit={onSubmit}
                    defaultValues={{
                      contact: {
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                        ubiAppUser: contact.ubiAppUser,
                        role: contact.role,
                      },
                      allActionPlans: !!contact.allActionPlans,
                      allPartitions: contact.hasPartitions,
                      customerId,
                      messaging: {
                        ...(!!contact.messageConfigurations?.length && {
                          events:
                            contact.messageConfigurations?.reduce<EventConfiguration>(
                              (result, { accountId, ...config }) => {
                                result[accountId] = {
                                  endTime: config.endTime,
                                  startTime: config.startTime,
                                  messageChannels: config.messageChannels,
                                  days: groupWorkDays(config.days) || [],
                                  eventGroupIds: config.eventGroupIds || [],
                                }
                                return result
                              },
                              {},
                            ),
                        }),
                        ...(!!contact.reportScheduleConfigurations?.length && {
                          reports:
                            contact.reportScheduleConfigurations?.reduce<ReportScheduleConfiguration>(
                              (result, { accountId, ...config }) => {
                                const { periodicity, days } =
                                  handleGetPeriodicity(config.periodicity)

                                result[accountId] = {
                                  id: config?.id,
                                  parameters: config.parameters,
                                  reportTime: config.reportTime,
                                  reportType: config.reportType,
                                  startReportTime: config.startReportTime,
                                  periodicity,
                                  days,
                                }

                                return result
                              },
                              {},
                            ),
                        }),
                      },
                      accounts: contact.accounts.map((account) => ({
                        id: account.id,
                        admin: account.admin,
                        aggregatedName: account.aggregatedAccountName || '',
                        code: '',
                        actionable: account.actionable,
                        isCentralUser: account.isCentralUser,
                        hasControl: account.hasControl,
                        serviceType: {
                          name: account.serviceType?.name || '',
                          color: account.serviceType?.color || '',
                        },
                      })),
                    }}
                  />
                </ContainerScreen>
              </div>
            </>
          ),
          loading: <Loader isVisible />,
          error: isError,
          empty: <></>,
        }[
          handleRenderState({
            data: !!contact,
            isError,
            isLoading,
          })
        ]
      }
    </>
  )
}

export default Update
